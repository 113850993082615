.app-container {
  min-height: 100vh !important;
  // max-height: 100vh !important;
  height: 100%;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-family: 'Rubik', sans-serif;
}

.toro-app-container {
  min-height: 100vh !important;
  height: 100%;
  max-width: 100%;
  font-family: 'Rubik', sans-serif;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.app-header-container {
  background-color: #fff;
  width: 100%;
  border-bottom: 0.2px solid rgb(213, 213, 213);
}

.toro-app-header-container {
  background-color: #f2f2f2;
  width: 100%;
  border-bottom: 0.2px solid rgb(213, 213, 213);
}

.routes-container {
  font-family: 'Rubik', sans-serif;
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  overflow: hidden;
}

.mobile-routes-container {
  font-family: 'Rubik', sans-serif;
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  scroll-behavior: smooth;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0.5rem !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(#676879d0, 0.2);
    border-radius: 20px;
    border: 3px solid #fff;
  }
}

.toro-routes-container {
  font-family: 'Rubik', sans-serif;
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  overflow: hidden;
}

.mobile-toro-routes-container {
  font-family: 'Rubik', sans-serif;
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  scroll-behavior: smooth;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.5rem !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(#555555, 0.2);
    border-radius: 20px;
    border: 3px solid #fff;
  }
}

.toro-routes-container-scroll {
  font-family: 'Rubik', sans-serif;
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  overflow-y: auto;
}

.routes-container-scroll {
  // height: calc(100vh - 4em);
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  overflow-y: auto;
}
