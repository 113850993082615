.container-inline {
  padding-inline: calc(1rem + 1.5625vw);
}

.container-block {
  padding-block-end: calc(1rem + 0.5625vh);
}

// breakpoints.scss
$x-small-max: 575.9px;
$small-min: 576px;
$small-max: 767.9px;
$medium-min: 768px;
$medium-max: 991.9px;
$large-min: 992px;
$large-max: 1199.9px;
$x-large-min: 1200px;
$x-large-max: 1439.9px;
$xx-large-min: 1440px;
$xx-large-max: 1919.9px;
$xxx-large-min: 1920px;
$xxx-large-max: 2559.9px;
$xxxx-large-min: 2560px;
