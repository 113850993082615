.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  //@media screen and (-webkit-min-device-pixel-ratio:0) {
  //    display: block;
  //}
}

.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.grid {
  display: -ms-grid;
  display: grid;
}

.grid-gap {
  gap: calc(1vw + 1vh + 1vmin);
}

.clean-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

@media (max-width: 586px) {
  .common-button-container {
    flex-display: row;
  }
}

.title-main {
  margin: 0px;
  line-height: normal;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: Rubik, serif;
}

@media (max-width: 586px) {
  .common-input,
  .common-input-label-form {
    width: 100%;
  }
}

.input-error {
  border: 1px solid #ff4d4f !important;
}

.input-container {
  display: -ms-inline-grid;
  display: inline-grid;
  border-radius: 4px;
  border: 1px solid #76778673;
  margin-block: 3px;
}

.input-error-text {
  color: #ff4d4f;
  position: absolute;
  bottom: -1rem;
}
