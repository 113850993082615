.nav-list {
  flex: 1;
  max-width: 100%;
  padding-block: 0.25rem;

  & .css-11xf28z-MuiTabs-root {
    min-height: unset !important;
  }

  li {
    &:nth-child(2) {
      flex: 1;
    }

    &:nth-child(3) {
      justify-content: flex-end;
    }
  }
}

@media (max-width: 767px) {
  .nav-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
