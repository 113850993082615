//Base
@import '../src/assets/global-scss/base/base.scss';
@import '../src/assets/global-scss/base/helper.scss';
@import '../src/assets/global-scss/base/layout.scss';
@import '../src/assets/global-scss/base/main-general.scss';

//Pages
@import 'shared/components/Navbar/Navbar.scss';

//Components
@import 'shared/components/Weather/Weather.scss';

// Break Points
// $mobile-viewport: 567px;
// $tablet-viewport: 768px;
// $narrow-viewport: 900px;
// $desktop-viewport: 1024px;
// $desktop-wide-viewport: 1200px;
// $wide-viewport: 1400px;
// $extra-wide-viewport: 1800px;

// Break Points
// Mobile: 320px to 480px
// Tablet: 481px to 768px
// Laptop: 769px to 1024px
// Desktop: 1025px and up

//main.less
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

@import 'assets/global-scss/shared/progressbar';

.confirmation-btn,
.cancel-btn {
  padding: 0.25rem;
  min-width: fit-content;
  border: 0;
  border-radius: 0.25rem;
  margin: 0.25rem;
  cursor: pointer;
}

.confirmation-btn {
  color: rgb(248, 248, 248);
  background-color: rgb(97, 206, 112);
}

.cancel-btn {
  color: black;
  background-color: #ffffff;
}

.map__info-box {
  background: #fff;
  box-sizing: border-box;
  position: relative;
  width: 182px;
  height: 161px;
  border-radius: 8px 8px 8px 8px;
  padding: 10px;

  & span {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    @media (min-width: 767.9px) and (max-height: 700px){
      font-size: 7px;
    }
  }
  & a {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    @media (min-width: 767.9px) and (max-height: 700px){
      font-size: 7px;
    }
  }

  @media (min-width: 767.9px) and (max-height: 700px){
    width: 100px;
    height: 90px;

  }
}
.map__info-box-imei-number {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 6px ;
  @media (min-width: 767.9px) and (max-height: 700px){
    font-size: 7px;
  }
}

.map__info-box-center-row {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  flex-direction: row; /* Items are laid out in a row */
  cursor: pointer;
}

.map__info-box-separator {
  height: 1px;
  width: 161px;
  background-color: #CFCFCF;
  margin: 12px 0;
  @media (min-width: 767.9px) and (max-height: 700px){
    width: 70px;
    margin: 6px 0;
  }
}

.map__info-box-btn {
  border: 0;
  right: 0;
  top: -0.15rem;
  position: absolute;
  background-color: transparent;
  padding: 0 0.5rem 0.5rem;
  cursor: pointer;

  & span {
    font-weight: 500;

    &:hover {
      font-weight: 700;
    }
  }
}

.ant-btn.ant-btn-custom:hover {
  color: #ffffff;
  border: inherit;
}

.ant-modal-content {
  background: #f8f8f8 !important;
}

input::placeholder {
  color: #676879 !important;
}

input,
textarea,
select {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  outline: none;
  padding: 3px 0px 3px 3px;
  margin: 5px 1px 3px 0px;
  border: 1px solid #dddddd;
}

input:focus,
textarea:focus,
select:focus,
input:hover,
textarea:hover,
select:hover {
  box-shadow: 0 0 3px #dddddd;
  border: inherit;
}

.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:hover {
  border-color: rgba(103, 104, 121, 0.4);
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-selector:hover,
.ant-select-open .ant-select-selector,
.ant-input:focus,
.ant-input:active,
.ant-input:hover {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
  outline: none;
}

:global {
  .ant-form-item-explain-error {
    font-size: 14px;
  }
}

.ant-form-item .ant-form-item-explain-error {
  position: absolute;
  font-size: 0.75rem;
}

button:focus {
  outline: 0;
}

button {
  box-shadow: none !important;
}

div > div > div > button.gm-control-active {
  /* Google maps */
  background: #3d3e48 !important;
  border-radius: 4px !important;
  margin-top: 0.25rem !important;
  right: 0.5rem;
  left: auto !important;
  border: 0.02em solid #ffffff54 !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 2px;

  &:active {
    border: 0.05em solid #ffffff9c !important;
  }

  &:focus {
    border: 0.05em solid #ffffff9c !important;
    outline: none !important;
  }

  &:hover {
    border-radius: 4px !important;
    border: 0.05em solid #ffffff9c !important;
    background: darken(#474753, 0.5) !important;
  }

  & > img {
    filter: brightness(0) invert(1) opacity(0.85) !important;
  }
}

.gmnoprint[role='menubar'] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  gap: 10px;
  cursor: pointer;
  bottom: calc(50vh - 53%) !important;
  right: 10px !important;
  margin: unset !important;

  div {
    button {
      width: 40px;
      height: 40px;
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
      background: #3d3e48 !important;
      border-radius: 4px !important;
      padding: 0.7rem !important;
      border: 0.02em solid #ffffff54 !important;
      -webkit-box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 2px;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 2px;

      &:active {
        border: 0.05em solid #ffffff9c !important;
      }

      &:focus {
        border: 0.05em solid #ffffff9c !important;
        outline: none !important;
      }

      &:hover {
        border-radius: 4px !important;
        border: 0.05em solid #ffffff9c !important;
        background: darken(#474753, 0.5) !important;
      }

      & span div img {
        filter: brightness(0) invert(1) opacity(0.85) !important;
      }
    }
  }
}

.gm-control-active.gm-fullscreen-control {
  display: none !important;
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-tree .ant-tree-treenode {
  font-size: 1.5rem;
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-tree
  .ant-tree-node-content-wrapper.ant-tree-node-selected,
:where(.css-dev-only-do-not-override-10ed4xt).ant-tree
  .ant-tree-checkbox
  + span.ant-tree-node-selected {
  background-color: #cccccc;
  padding: 0.5rem;
  width: 100%;
}

.PhoneInputInput {
  border: 1px solid;
  border-color: #76778673;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

input::file-selector-button {
  font-weight: bold;
  color: #474753;
  padding: 0.5em;
  border: thin solid grey;
  border-radius: 3px;
}

.common-input input.form-control {
  width: 100%;
}

.common-input-label-form input.form-control {
  width: 100%;
}

.color-picker-container {
  width: 3rem;
  height: 3rem;
  padding: 0;
}

.color-picker-container-custom {
  margin-left: 0.5rem;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker {
  border: 1px solid #76778673 !important;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: #707070;
  border-color: #707070;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  // height: auto!important;
}

@media not all and (max-height: 899px) and (min-resolution: 0.1dpi) {
  .safari-only {
    -webkit-padding-after: 1.7rem;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .map-icon-settings {
    position: absolute;
  }
}

.btn-action-container {
  padding-inline: 2rem;
}
