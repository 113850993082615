.gm-style-moc {
  height: unset !important;
}
.gmnoprint a,
.gmnoprint span {
  display: none;
}
.gmnoprint div {
  background: none !important;
}
#GMapsID div div a div img {
  display: none;
}
