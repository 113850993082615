.backArrow {
  max-width: 30px;
  & svg {
    fill: #61ce70;
    & path {
      fill: #61ce70;
    }
  }
}
.toro-backArrow {
  max-width: 30px;
  & svg {
    fill: #42a364;
    & path {
      fill: #cc0000;
    }
  }
}
