* {
  box-sizing: border-box;
}

html {
  max-width: 100vw;
  height: 100vh;
  overflow: hidden;
}

body {
  margin: 0;
  font-size: calc(14px + 0.25vw);
  overflow: hidden;
  max-width: 100vw !important;
  height: 100% !important;
  max-height: 100vh;
  font-family: 'Rubik', 'serif,' -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;

  @media screen and (max-height: 751px) {
    overflow-y: auto;
  }
}

#root {
  height: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body .ant-tooltip-inner {
  color: #676879 !important;
  border-radius: 4px;
}

.logo-center {
  text-align: center;
}
